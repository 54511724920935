function IconAdd(props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="11" cy="11" r="11" fill="#E8E8E8" />
      <path
        d="M10.0212 15V15.2H10.2212H11.3717H11.5717V15V11.7487H14.5929H14.7929V11.5487V10.4513V10.2513H14.5929H11.5717V7V6.8H11.3717H10.2212H10.0212V7V10.2513H7H6.8V10.4513V11.5487V11.7487H7H10.0212V15Z"
        fill="#242424"
        stroke="#242424"
        strokeWidth="0.4"
      />
    </svg>
  );
}

export default IconAdd;
