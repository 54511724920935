import { Icon } from '@chakra-ui/react';

export default function IconAccounting() {
  return (
    <Icon
      w="20px"
      h="20px"
      viewBox="0 0 24 24"
      fill="black"
      _dark={{ fill: 'white' }}
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm11.793 6.793l-2.45 2.45-2.121-2.122-4.243 4.243 1.414 1.414 2.829-2.828 2.121 2.121 3.864-3.864L18 13V8h-5l1.793 1.793z"></path>
      </g>
    </Icon>
  );
}
