function IconFBCopyLink() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.03908C0 10.5192 2.52758 13.4132 5.83333 14V8.94425H4.08333V7H5.83333V5.44425C5.83333 3.69425 6.96092 2.72242 8.55575 2.72242C9.06092 2.72242 9.60575 2.8 10.1109 2.87758V4.66667H9.21667C8.36092 4.66667 8.16667 5.09425 8.16667 5.63908V7H10.0333L9.72242 8.94425H8.16667V14C11.4724 13.4132 14 10.5198 14 7.03908C14 3.1675 10.85 0 7 0C3.15 0 0 3.1675 0 7.03908Z"
        fill="#003A92"
      />
    </svg>
  );
}

export default IconFBCopyLink;
