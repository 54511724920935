import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import CommunityItem from 'app/components/Community';
import Empty from 'app/components/Empty';
import SearchAndSort from 'app/components/SearchAndSort';
import SkeletonItem from 'app/components/SkeletonItem';
import { generateArray } from 'app/helpers/functions';
import { useCommunity } from 'app/hooks/Community/useCommunity';
import { useFilters } from 'app/hooks/filters/userFilters';
import { useCallback, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import DropDown from 'app/components/Common/Dropdowns';
import { useSections } from 'app/hooks/sections/useSections';
import Pagination from 'app/components/Pagination';

interface ContributorsInLabelProps {
  labelId: string;
  labelDetail?: any;
}

export function ContributorsInLabel({
  labelId,
  labelDetail,
}: ContributorsInLabelProps) {
  const {
    onGetCommunity,
    handleChange,
    handleChangeFilter,
    setFilter,
    users,
    isLoading,
    searchValue,
    filter,
    totalPage,
  } = useCommunity();
  const { listOptionsSortByCommunity } = useFilters();

  const { sections } = useSections();

  const sectionsOptions = useMemo(() => {
    return [
      {
        label: 'All Labels',
        value: 'all',
      },
    ].concat(
      ([...sections] || [])
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })
        .map(section => {
          return { label: section.name, value: section._id };
        }),
    );
  }, [sections]);

  useEffect(() => {
    onGetCommunity();
  }, [onGetCommunity]);

  useEffect(() => {
    setFilter(prev => ({
      ...prev,
      labelId: labelId,
    }));
  }, [labelId, setFilter]);

  const renderContent = useCallback(() => {
    return (
      <Box mt="20px">
        <SimpleGrid
          gridGap="10px"
          rowGap="15px"
          columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }}
        >
          {isLoading
            ? generateArray(4).map(item => (
                <SkeletonItem
                  className={styles.loadingContributor}
                  height="100%"
                  key={item}
                />
              ))
            : users.map((item, idx) => (
                <CommunityItem key={item._id} user={item} />
              ))}
        </SimpleGrid>
        {!isLoading && users.length === 0 && <Empty />}
      </Box>
    );
  }, [isLoading, users]);

  return (
    <Box>
      <Box>
        <Text className={styles.header}>Contributors with</Text>
        <Text className={styles.content}>{labelDetail?.name}</Text>
      </Box>
      <Flex
        alignContent="center"
        alignItems="center"
        gridGap="12px"
        flexWrap={{ base: 'wrap', md: 'unset' }}
      >
        <Flex gridGap="12px" alignItems="center">
          <Box>
            <Text
              fontSize="12px"
              fontWeight="600"
              color={useColorModeValue('#616161', '#fff')}
            >
              Label:
            </Text>
            <DropDown
              width="170px"
              filters={sectionsOptions}
              handleChangeDropDown={value =>
                handleChangeFilter(value, 'labelId')
              }
            />
          </Box>
        </Flex>
        <SearchAndSort
          listSort={listOptionsSortByCommunity}
          searchValue={searchValue}
          handleChange={handleChange}
          valueSort={filter?.sort}
          handleChangeFilter={handleChangeFilter}
          placeHolder={'Search username of contributor'}
        />
      </Flex>

      {renderContent()}
      <Pagination totalPage={totalPage} setFilter={setFilter} />
    </Box>
  );
}
