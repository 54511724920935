import { useMediaScreen } from 'app/hooks/mediaScreen/useMediaScreen';

function IconPrevious({ onClick }) {
  const { isLargerThan500 } = useMediaScreen();
  return (
    <svg
      width={isLargerThan500 ? '23' : '15'}
      height={isLargerThan500 ? '24' : '15'}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M19.4453 5.00643C19.4454 4.75182 19.3755 4.5021 19.2432 4.28454C19.1109 4.06699 18.9214 3.88998 18.6953 3.77286C18.4692 3.65574 18.2153 3.60301 17.9613 3.62045C17.7073 3.63788 17.463 3.7248 17.255 3.87171L7.34809 10.8648C7.16639 10.993 7.01814 11.163 6.91582 11.3605C6.8135 11.5579 6.76009 11.7771 6.76009 11.9995C6.76009 12.2219 6.8135 12.441 6.91582 12.6385C7.01814 12.836 7.16639 13.006 7.34809 13.1342L17.255 20.1273C17.463 20.2742 17.7073 20.3611 17.9613 20.3785C18.2153 20.396 18.4692 20.3432 18.6953 20.2261C18.9214 20.109 19.1109 19.932 19.2432 19.7144C19.3755 19.4969 19.4454 19.2472 19.4453 18.9925V5.00643Z"
        fill="#C5C5C5"
      />
      <path
        d="M2.77799 4.70768C2.77799 4.43142 2.88774 4.16646 3.08309 3.97111C3.27844 3.77576 3.54339 3.66602 3.81966 3.66602C4.09593 3.66602 4.36088 3.77576 4.55623 3.97111C4.75158 4.16646 4.86133 4.43142 4.86133 4.70768V19.291C4.86133 19.5673 4.75158 19.8322 4.55623 20.0276C4.36088 20.2229 4.09593 20.3327 3.81966 20.3327C3.54339 20.3327 3.27844 20.2229 3.08309 20.0276C2.88774 19.8322 2.77799 19.5673 2.77799 19.291V4.70768Z"
        fill="#C5C5C5"
      />
    </svg>
  );
}

export default IconPrevious;
