import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import { useMediaScreen } from 'app/hooks/mediaScreen/useMediaScreen';
import { memo } from 'react';

interface Props {
  children: React.ReactNode;
  toggleShowFilter: any;
}

export const FilterModal = memo(({ children, toggleShowFilter }: Props) => {
  const { isLargerThan500, isLargerThan780, isLargerThan768 } =
    useMediaScreen();

  const bgFilter = useColorModeValue('#efefef', '#393d45');
  const closeFilterButtonColor = useColorModeValue('#e2dddd', '#fff');
  const bgCloseFilterButtonColor = useColorModeValue('#d11515', '#c80e2a');

  return (
    <Box
      bg={bgFilter}
      w={!isLargerThan500 ? '100%' : !isLargerThan768 ? '350px' : '320px'}
      right="0px"
      position="fixed"
      top={!isLargerThan780 ? '60px' : !isLargerThan500 ? '0px' : '117px'}
      zIndex={100}
      padding="16px"
      boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
    >
      <Box
        height={
          !isLargerThan780
            ? 'calc(100vh - 200px)'
            : !isLargerThan500
            ? 'calc(100vh - 140px)'
            : 'calc(100vh - 260px)'
        }
        overflowY="scroll"
        mt="50px"
        mb="60px"
        mr="-16px"
        pr="16px"
      >
        <Flex
          position="absolute"
          top="16px"
          justifyContent="center"
          alignItems="center"
          pb="4px"
          right="16px"
          textColor="white"
          w="30px"
          h="30px"
          bg={bgCloseFilterButtonColor}
          zIndex={9}
          onClick={toggleShowFilter}
          cursor="pointer"
          fontSize="34px"
          opacity="0.6"
          fontWeight="bold"
          borderRadius="full"
        >
          <Text
            textColor={closeFilterButtonColor}
            fontSize="14px"
            fontWeight="bold"
          >
            x
          </Text>
        </Flex>
        <Box>
          <Text fontSize="25px" fontWeight={700} lineHeight="32px">
            FILTERS
          </Text>
          <Text fontSize="12px" fontWeight={600} lineHeight="16px" my="10px">
            Make adjustments to the filters to update the table results.
          </Text>
        </Box>
        {children}
      </Box>
    </Box>
  );
});
