import { useMediaScreen } from 'app/hooks/mediaScreen/useMediaScreen';

function IconNext({ onClick }) {
  const { isLargerThan500 } = useMediaScreen();
  return (
    <svg
      width={isLargerThan500 ? '23' : '15'}
      height={isLargerThan500 ? '24' : '15'}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M2.77734 5.00643C2.77728 4.75182 2.8472 4.5021 2.97947 4.28454C3.11174 4.06699 3.30127 3.88998 3.52734 3.77286C3.75341 3.65574 4.00732 3.60301 4.26133 3.62045C4.51534 3.63788 4.75967 3.7248 4.96762 3.87171L14.8746 10.8648C15.0563 10.993 15.2045 11.163 15.3068 11.3605C15.4092 11.5579 15.4626 11.7771 15.4626 11.9995C15.4626 12.2219 15.4092 12.441 15.3068 12.6385C15.2045 12.836 15.0563 13.006 14.8746 13.1342L4.96762 20.1273C4.75967 20.2742 4.51534 20.3611 4.26133 20.3785C4.00732 20.396 3.75341 20.3432 3.52734 20.2261C3.30127 20.109 3.11174 19.932 2.97947 19.7144C2.8472 19.4969 2.77728 19.2472 2.77734 18.9925V5.00643Z"
        fill="#C5C5C5"
      />
      <path
        d="M19.4447 4.70768C19.4447 4.43142 19.3349 4.16646 19.1396 3.97111C18.9442 3.77576 18.6793 3.66602 18.403 3.66602C18.1267 3.66602 17.8618 3.77576 17.6664 3.97111C17.4711 4.16646 17.3613 4.43142 17.3613 4.70768V19.291C17.3613 19.5673 17.4711 19.8322 17.6664 20.0276C17.8618 20.2229 18.1267 20.3327 18.403 20.3327C18.6793 20.3327 18.9442 20.2229 19.1396 20.0276C19.3349 19.8322 19.4447 19.5673 19.4447 19.291V4.70768Z"
        fill="#C5C5C5"
      />
    </svg>
  );
}

export default IconNext;
