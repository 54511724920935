import { Box } from '@chakra-ui/react';
import { HelmetPage } from 'app/components/HelmetPage';
import ReleaseList from 'app/components/ReleaseList';
import { PageHeaderContext } from 'app/contexts/PageHeaderContext';
import { useReleases } from 'app/hooks/releases/useReleases';
import { renderDraftToHtml } from 'app/utils/renderDraftToHtml';
import { useContext } from 'react';
import { useModeTheme } from 'app/hooks/ColorDarkMode/useModeTheme';
import { BannerListLabel } from 'app/components/BannerListLabel';
import Crate from 'app/components/Crate';

export function ReleasesPage() {
  const { pageHeader }: any = useContext(PageHeaderContext);
  const { isDarkMode } = useModeTheme();

  const { releases = [] } = useReleases();

  return (
    <>
      <HelmetPage title="Multipacks" />
      <BannerListLabel />
      <Crate />
      {pageHeader?.release && (
        <Box
          mb="20px"
          className={
            isDarkMode ? 'pageTopHeaderDarkMode' : 'pageTopHeaderLightMode'
          }
        >
          {renderDraftToHtml(pageHeader?.release)}
        </Box>
      )}
      <ReleaseList releases={releases} />
    </>
  );
}
