import { useCallback } from 'react';
import { useCommunity } from '../Community/useCommunity';
import { useGenres } from '../genres/useGenres';
import { useSections } from '../sections/useSections';
import { useTags } from '../tags/useTags';
import { useTracksKeys } from '../trackKeys/useTrackKeys';
import { useTracks } from '../tracks/useTracks';
import { SORT_TYPE } from 'app/constants/enum';

export const useInitFiltersValue = () => {
  const { onGetTags } = useTags();
  const { onGetGenres } = useGenres();
  const { onGetSections } = useSections();
  const { onGetTrackKeys } = useTracksKeys();
  const { onGetContributors, onGetCommunity } = useCommunity();
  const { getTokenMax } = useTracks();

  const onInitFilterValues = useCallback(() => {
    onGetTags({ search: '', sort: 'name@asc' });
    onGetGenres({ search: '', sort: 'name@asc' });
    onGetSections({
      search: '',
      sort: SORT_TYPE.ORDER_ASC,
    });
    onGetTrackKeys();
    onGetContributors();
    onGetCommunity({ search: '', sort: 'username@asc' });
    getTokenMax();
  }, [
    onGetTags,
    onGetGenres,
    onGetSections,
    onGetTrackKeys,
    onGetContributors,
    onGetCommunity,
    getTokenMax,
  ]);

  return { onInitFilterValues };
};
