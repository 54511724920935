import { Icon } from '@chakra-ui/react';

export default function IconMultipacks() {
  return (
    <Icon
      w="20px"
      h="20px"
      viewBox="0 0 24 24"
      fill="black"
      _dark={{ fill: 'white' }}
    >
      <path
        fill-rule="evenodd"
        d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z"
        clip-rule="evenodd"
      />
    </Icon>
  );
}
