import { Box } from '@chakra-ui/react';
import { Banner } from 'app/components/Banner';
import { HelmetPage } from 'app/components/HelmetPage';
import LabelList from 'app/components/LabelList';
import { PageHeaderContext } from 'app/contexts/PageHeaderContext';
import { renderDraftToHtml } from 'app/utils/renderDraftToHtml';
import { useContext } from 'react';
import { useModeTheme } from 'app/hooks/ColorDarkMode/useModeTheme';
import Crate from 'app/components/Crate';

export function LabelsPage() {
  const { pageHeader }: any = useContext(PageHeaderContext);
  const { isDarkMode } = useModeTheme();

  return (
    <>
      <HelmetPage title="Labels" />
      <Banner />
      <Crate />
      {pageHeader?.label && (
        <Box
          mb="20px"
          className={
            isDarkMode ? 'pageTopHeaderDarkMode' : 'pageTopHeaderLightMode'
          }
        >
          {renderDraftToHtml(pageHeader?.label)}
        </Box>
      )}
      <LabelList />
    </>
  );
}
