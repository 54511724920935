interface IconRemoveOptionProps {
  onClick?: () => void;
}
function IconRemoveOption({ onClick }: IconRemoveOptionProps) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <path
        d="M6 0C2.68575 0 0 2.6865 0 6C0 9.3135 2.68575 12 6 12C9.31425 12 12 9.3135 12 6C12 2.6865 9.31425 0 6 0ZM8.78025 7.71975C8.92088 7.86038 8.99989 8.05112 8.99989 8.25C8.99989 8.44888 8.92088 8.63962 8.78025 8.78025C8.63962 8.92088 8.44888 8.99989 8.25 8.99989C8.05112 8.99989 7.86038 8.92088 7.71975 8.78025L6 7.0605L4.28025 8.78025C4.21076 8.85014 4.12814 8.9056 4.03714 8.94345C3.94614 8.98129 3.84856 9.00078 3.75 9.00078C3.65144 9.00078 3.55386 8.98129 3.46286 8.94345C3.37186 8.9056 3.28924 8.85014 3.21975 8.78025C3.15004 8.71066 3.09474 8.62801 3.057 8.53702C3.01927 8.44603 2.99985 8.3485 2.99985 8.25C2.99985 8.1515 3.01927 8.05397 3.057 7.96298C3.09474 7.87199 3.15004 7.78934 3.21975 7.71975L4.9395 6L3.21975 4.28025C3.07912 4.13962 3.00011 3.94888 3.00011 3.75C3.00011 3.55112 3.07912 3.36038 3.21975 3.21975C3.36038 3.07912 3.55112 3.00011 3.75 3.00011C3.94888 3.00011 4.13962 3.07912 4.28025 3.21975L6 4.9395L7.71975 3.21975C7.86038 3.07912 8.05112 3.00011 8.25 3.00011C8.44888 3.00011 8.63962 3.07912 8.78025 3.21975C8.92088 3.36038 8.99989 3.55112 8.99989 3.75C8.99989 3.94888 8.92088 4.13962 8.78025 4.28025L7.0605 6L8.78025 7.71975Z"
        fill="#B80000"
      />
    </svg>
  );
}

export default IconRemoveOption;
