import { Box } from '@chakra-ui/react';
import { Banner } from 'app/components/Banner';
import { HelmetPage } from 'app/components/HelmetPage';
import PackageSubscriptions from 'app/components/PackageSubscriptions';
import axiosService from 'app/services/axios.service';
import { path } from 'ramda';
import { useCallback, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import { PageHeaderContext } from 'app/contexts/PageHeaderContext';
import { renderDraftToHtml } from 'app/utils/renderDraftToHtml';
import { useModeTheme } from 'app/hooks/ColorDarkMode/useModeTheme';

export function Services() {
  const { pageHeader }: any = useContext(PageHeaderContext);
  const { isDarkMode } = useModeTheme();

  const [dataSubAppId, setDataSubAppId] = useState<any>();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const subAppId = searchParams.get('subAppId');

  localStorage.removeItem('subApp');
  localStorage.removeItem('services');

  const BASE_SUBAPPID_URL = '/v1/subscription-applications';

  const handeGetSubAppId = useCallback(async () => {
    const response = await axiosService.get(`${BASE_SUBAPPID_URL}/${subAppId}`);
    setDataSubAppId(path(['data', 'data'], response));
  }, [subAppId]);

  useEffect(() => {
    if (subAppId) {
      handeGetSubAppId();
    }
  }, [handeGetSubAppId, subAppId]);

  return (
    <Box>
      <HelmetPage title="Services" />
      <Banner />
      {pageHeader?.subscription && (
        <Box
          mb="20px"
          className={
            isDarkMode ? 'pageTopHeaderDarkMode' : 'pageTopHeaderLightMode'
          }
        >
          {renderDraftToHtml(pageHeader?.subscription)}
        </Box>
      )}
      <Box mt="20px">
        <PackageSubscriptions dataSubAppId={dataSubAppId} />
      </Box>
    </Box>
  );
}
