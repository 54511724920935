import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Banner } from 'app/components/Banner';
import ButtonTop from 'app/components/ButtonTop';
import Crate from 'app/components/Crate';
import Empty from 'app/components/Empty';
import { HelmetPage } from 'app/components/HelmetPage';
import LabelInfo from 'app/components/LabelInfo';
import ReleaseItem from 'app/components/ReleaseItem';
import SkeletonItem from 'app/components/SkeletonItem';
import { TextTop100 } from 'app/components/TextTop100';
import TimeSelector from 'app/components/TimeSelector';
import TrackItem from 'app/components/TrackItem';
import { renderLoadingTracks } from 'app/components/TrackUtils/track';
import { PageHeaderContext } from 'app/contexts/PageHeaderContext';
import { generateArray } from 'app/helpers/functions';
import { useModeTheme } from 'app/hooks/ColorDarkMode/useModeTheme';
import { useSections } from 'app/hooks/sections/useSections';
import { useTopReleaseByLabel } from 'app/hooks/topMultipackByLabel';
import { useTopTrack } from 'app/hooks/topTrack';
import { renderDraftToHtml } from 'app/utils/renderDraftToHtml';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function WelcomePage() {
  const { pageHeader }: any = useContext(PageHeaderContext);
  const { isDarkMode } = useModeTheme();
  const { sections = [], onGetSections, isLoading } = useSections();
  const {
    selectedTime,
    handleTimeChange,
    updateWishlistStatus,
    isShowModalBuyTrackByStarPageChart,
    onCloseModalBuyTrackByStarPageChart,
    isShowModalBuyTrackBySubPageChart,
    onCloseModalBuyTrackBySubPageChart,
    isLoadingBuyTrackPageChart,
    buyTrackByStarPageChart,
    buyTrackBySubPageChart,
    dispatch,
    actions,
    isFilterGlobalPageHome,
    visibleTracksIndex,
    handleClick,
    loadingIndexes,
    itemData,
    setVisibleTracksIndex,
    setItemData,
    handleOpenBuyTrack,
    handleOpenBuyTrackBySub,
    trackId,
  } = useTopTrack();

  const {
    selectedTimeForRelease,
    handleTimeChangeForTopRelease,
    updateWishlistStatusForTopRelease,
    visibleReleasesIndex,
    handleClickTopRelease,
    loadingReleaseIndexes,
    itemReleaseData,
  } = useTopReleaseByLabel();

  const { t } = useTranslation();
  const { isLightMode } = useModeTheme();

  useEffect(() => {
    onGetSections();
  }, [onGetSections]);

  useEffect(() => {
    if (isFilterGlobalPageHome) {
      setVisibleTracksIndex({});
      setItemData({});
      dispatch(actions.isFilterGlobalPageHome(false));
    }
  }, [
    actions,
    dispatch,
    isFilterGlobalPageHome,
    setItemData,
    setVisibleTracksIndex,
  ]);

  const renderUILoadMore = useCallback(
    numberItem => (
      <Box>
        <SimpleGrid
          gridGap="10px"
          rowGap="15px"
          columns={{ base: 1, sm: 2, md: 4, lg: 5 }}
        >
          {generateArray(numberItem).map(item => (
            <SkeletonItem borderRadius="10px" key={item} />
          ))}
        </SimpleGrid>
      </Box>
    ),
    [],
  );

  const Header = ({
    title,
    subtitle,
    selectedTime,
    onChangeTime,
    isTop10ListsPage,
  }) => (
    <Box
      mt="20px"
      mb="10px"
      borderRadius="5px"
      bg={isLightMode ? '#f8f8f8' : 'none'}
      p="10px"
    >
      <Text fontSize={30} lineHeight="25px" fontFamily="RubikDoodleShadow">
        {title}
      </Text>
      <Text fontSize={20} lineHeight="25px" fontWeight={700} mb="20px">
        {subtitle}
      </Text>
      <TimeSelector
        isTop10ListsPage={isTop10ListsPage}
        selectedTime={selectedTime}
        onChangeTime={onChangeTime}
      />
    </Box>
  );

  const renderListTopTrackByLabel = () => {
    if (isLoading) {
      return renderLoadingTracks(5);
    }
    if (sections && sections.length <= 0) return <Empty />;
    return (
      <SimpleGrid
        gap="10px"
        columns={{ base: 1 }}
        alignItems="center"
        mt="20px"
      >
        {sections?.map((item: any, idx: any) => {
          const dataKey = `${idx}-${selectedTime}`;
          return (
            <Box key={idx}>
              <Flex
                position="relative"
                border="1px solid #ddd4d4"
                borderRadius="5px"
                borderBottomLeftRadius="0px"
                borderBottomRightRadius="0px"
                h="80px"
                w="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabelInfo fontMobile="system-ui" item={item} />
                <ButtonTop
                  id={item._id}
                  text=" TOP 20 TRACKS"
                  handClickTop={handleClick}
                  selectedTime={selectedTime}
                  idx={idx}
                  value="label"
                />
              </Flex>
              {visibleTracksIndex[dataKey] && (
                <Box border="1px solid #ddd4d4" borderTop="0px" pb="5px">
                  {loadingIndexes.includes(dataKey) ? (
                    renderLoadingTracks(5)
                  ) : (
                    <>
                      {itemData[dataKey] && itemData[dataKey].length === 0 && (
                        <Box my="15px">
                          <Empty />
                        </Box>
                      )}
                      {itemData[dataKey] && itemData[dataKey].length > 0 && (
                        <Box pt="10px">
                          {itemData[dataKey].map((track, index) => {
                            return (
                              <TrackItem
                                track={track}
                                index={index + 1}
                                key={track._id}
                                updateWishlistStatus={updateWishlistStatus}
                                handleOpenBuyTrackBySub={
                                  handleOpenBuyTrackBySub
                                }
                                handleOpenBuyTrack={handleOpenBuyTrack}
                                isChartsPage
                              />
                            );
                          })}
                        </Box>
                      )}
                      <TextTop100
                        idOrSlug={item.slug}
                        selectedTime={selectedTime}
                        page="labels"
                      />
                    </>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </SimpleGrid>
    );
  };

  const renderListTopReleaseByLabel = () => {
    if (isLoading) {
      return renderLoadingTracks(5);
    }
    if (sections && sections.length <= 0) return <Empty />;
    return (
      <SimpleGrid
        gap="10px"
        columns={{ base: 1 }}
        alignItems="center"
        my="20px"
      >
        {sections?.map((item: any, idx: any) => {
          const dataKey = `${idx}-${selectedTimeForRelease}`;
          return (
            <Box key={idx}>
              <Flex
                position="relative"
                border="1px solid #ddd4d4"
                borderRadius="5px"
                borderBottomLeftRadius="0px"
                borderBottomRightRadius="0px"
                h="80px"
                w="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabelInfo fontMobile="system-ui" item={item} />
                <ButtonTop
                  id={item._id}
                  text="TOP 20 MULTIPACKS"
                  handClickTop={handleClickTopRelease}
                  selectedTime={selectedTimeForRelease}
                  idx={idx}
                />
              </Flex>
              {visibleReleasesIndex[dataKey] && (
                <Box border="1px solid #bdbdbd" borderTop="0px">
                  {loadingReleaseIndexes.includes(dataKey) ? (
                    <Box my="15px">{renderUILoadMore(5)}</Box>
                  ) : (
                    <>
                      {itemReleaseData[dataKey].length === 0 && (
                        <Box my="15px">
                          <Empty />
                        </Box>
                      )}
                      {itemReleaseData[dataKey].length > 0 && (
                        <Box mb="15px" p="10px">
                          <SimpleGrid
                            gridGap="10px"
                            rowGap="15px"
                            columns={{ base: 1, sm: 3, lg: 5 }}
                          >
                            {itemReleaseData[dataKey].map((item, idx) => (
                              <ReleaseItem
                                isChartsPage
                                updateWishlistStatusForTopRelease={
                                  updateWishlistStatusForTopRelease
                                }
                                key={item._id}
                                release={item}
                              />
                            ))}
                          </SimpleGrid>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </SimpleGrid>
    );
  };

  return (
    <Box position="relative">
      <HelmetPage title="Welcome" />
      <Banner />
      <Crate />
      {pageHeader?.home && (
        <Box
          mb="20px"
          className={
            isDarkMode ? 'pageTopHeaderDarkMode' : 'pageTopHeaderLightMode'
          }
        >
          {renderDraftToHtml(pageHeader?.home)}
        </Box>
      )}
      <Header
        title="TOP 20 TRACKS"
        subtitle="BY LABEL"
        selectedTime={selectedTime}
        onChangeTime={newTime => handleTimeChange(newTime, '', 'label')}
        isTop10ListsPage
      />
      {renderListTopTrackByLabel()}
      <Header
        title="TOP 20 MULTIPACKS"
        subtitle="BY LABEL"
        selectedTime={selectedTimeForRelease}
        onChangeTime={newTime => handleTimeChangeForTopRelease(newTime, 'item')}
        isTop10ListsPage
      />
      {renderListTopReleaseByLabel()}
      <Modal
        isOpen={isShowModalBuyTrackByStarPageChart}
        onClose={() => onCloseModalBuyTrackByStarPageChart()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('buyTrack.confirm')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t('buyTrack.useStar', { price: 1 })}</ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoadingBuyTrackPageChart}
              onClick={() => {
                buyTrackByStarPageChart(trackId);
              }}
              variant="ghost"
            >
              {t('buyTrack.continue')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        onClose={onCloseModalBuyTrackBySubPageChart}
        isOpen={isShowModalBuyTrackBySubPageChart}
      >
        <Box>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>You are buying track by subscription?</ModalHeader>

            <ModalFooter>
              <Button
                bg="#EDF2F7"
                color="#1A202C"
                onClick={onCloseModalBuyTrackBySubPageChart}
              >
                Close
              </Button>
              <Button
                bg="#EDF2F7"
                color="#1A202C"
                ml="10px"
                onClick={() => buyTrackBySubPageChart(trackId)}
              >
                Yes
              </Button>
            </ModalFooter>
          </ModalContent>
        </Box>
      </Modal>
    </Box>
  );
}
