function IconCopyLink() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.25V2.5C10 2.36458 9.95052 2.2474 9.85156 2.14844C9.7526 2.04948 9.63542 2 9.5 2H5.75C5.53125 2 5.3776 2.10156 5.28906 2.30469C5.20052 2.51823 5.23698 2.70052 5.39844 2.85156L6.52344 3.97656L2.35156 8.14844C2.2526 8.2474 2.20312 8.36458 2.20312 8.5C2.20312 8.63542 2.2526 8.7526 2.35156 8.85156L3.14844 9.64844C3.2474 9.7474 3.36458 9.79688 3.5 9.79688C3.63542 9.79688 3.7526 9.7474 3.85156 9.64844L8.02344 5.47656L9.14844 6.60156C9.24219 6.70052 9.35938 6.75 9.5 6.75C9.5625 6.75 9.6276 6.73698 9.69531 6.71094C9.89844 6.6224 10 6.46875 10 6.25ZM12 2.25V9.75C12 10.3698 11.7799 10.8997 11.3398 11.3398C10.8997 11.7799 10.3698 12 9.75 12H2.25C1.63021 12 1.10026 11.7799 0.660156 11.3398C0.220052 10.8997 0 10.3698 0 9.75V2.25C0 1.63021 0.220052 1.10026 0.660156 0.660156C1.10026 0.220052 1.63021 0 2.25 0H9.75C10.3698 0 10.8997 0.220052 11.3398 0.660156C11.7799 1.10026 12 1.63021 12 2.25Z"
        fill="#0055D6"
      />
    </svg>
  );
}

export default IconCopyLink;
