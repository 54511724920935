import { Box, Flex, Text } from '@chakra-ui/react';
import { useCommunity } from 'app/hooks/Community/useCommunity';
import { useGenres } from 'app/hooks/genres/useGenres';
import { useModalCrate } from 'app/hooks/ModalCrate/useModalCrate';
import { useSections } from 'app/hooks/sections/useSections';
import { useTags } from 'app/hooks/tags/useTags';
import { useTracksKeys } from 'app/hooks/trackKeys/useTrackKeys';
import { useItemCrate } from 'app/hooks/useItemCrate/useItemCrate';
import { selectSliceCrates } from 'app/pages/PageCrate/slice/selector';
import { FiEdit2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import CreateCrateModal from './CreateCrateModal';
import ModalConfirmCrate from './ModalConfirmCrate';
import { useLocation } from 'react-router-dom';

export default function Crate() {
  const { handleClickCrate } = useItemCrate();
  const { myTrackCrates, filterRules } = useSelector(selectSliceCrates);
  const {
    handleEditCrate,
    isModalOpen,
    setIsModalOpen,
    handleShowModalCreateCrate,
    yesModalDelete,
    modalDelete,
    isOpen,
    onCloseModalConfirmDeleteCrate,
  } = useModalCrate();
  const { pathname } = useLocation();
  const { sections = [] } = useSections();
  const { genres = [] } = useGenres();
  const { tags = [] } = useTags();
  const { contributors } = useCommunity();
  const { trackKeys = [] } = useTracksKeys();

  return (
    <Box mt="-10px" mb="40px">
      <Text textDecoration="underline" fontSize="16px" fontWeight={700}>
        My Crates
      </Text>
      <Flex flexWrap="wrap" gridGap="6px" mt="5px">
        {myTrackCrates.map((quickLink, idx) => {
          return (
            <Flex
              key={idx}
              bg="#000"
              p="0px 6px"
              pr={quickLink.role !== 'admin' ? '1px' : '6px'}
              gridGap="3px"
              alignItems="center"
            >
              <Box
                onClick={() => handleClickCrate(quickLink)}
                cursor="pointer"
                fontSize="16px"
                fontWeight={700}
                color={
                  pathname.includes('/tracks') &&
                  quickLink._id === filterRules._id
                    ? '#59ff00'
                    : '#fff'
                }
                lineHeight="15px"
              >
                {quickLink?.name}
              </Box>
              {quickLink?.role !== 'admin' && (
                <Flex
                  onClick={() => {
                    handleEditCrate(quickLink);
                  }}
                  border="1px solid #8c8787"
                  borderRadius="50%"
                  w="22px"
                  height="22px"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                >
                  <FiEdit2 color="#fff" size="14px" />
                </Flex>
              )}
              {quickLink?.role !== 'admin' && (
                <Flex
                  onClick={() => {
                    modalDelete(quickLink._id);
                  }}
                  border="1px solid #8c8787"
                  borderRadius="50%"
                  w="22px"
                  height="22px"
                  justifyContent="center"
                  alignItems="center"
                  color="#FFF"
                  cursor="pointer"
                >
                  <span style={{ marginBottom: '2px' }}>x</span>
                </Flex>
              )}
            </Flex>
          );
        })}
        <Box
          p="1px 6px"
          bg="#000"
          color="#fff"
          borderRadius="4px"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => handleShowModalCreateCrate()}
          cursor="pointer"
        >
          <span>+</span>
        </Box>
        <CreateCrateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          genres={genres}
          contributors={contributors}
          labels={sections}
          tags={tags}
          trackKeys={trackKeys}
        />
      </Flex>
      <ModalConfirmCrate
        isOpen={isOpen}
        onClose={onCloseModalConfirmDeleteCrate}
        yesModalDelete={yesModalDelete}
      />
    </Box>
  );
}
