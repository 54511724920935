import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Release } from 'app/models';
import { formatDate } from 'app/utils/date';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';
import CartButton from '../CartButton';
import { formatMoney } from 'app/utils/currency';
import { FaUser } from 'react-icons/fa';
import { Role } from 'app/constants/enum';

interface ReleaseItemProps {
  release: Release;
  isChartsPage?: boolean;
  updateWishlistStatusForTopRelease?: any;
}

const ReleaseItem = ({
  release,
  isChartsPage,
  updateWishlistStatusForTopRelease,
}: ReleaseItemProps) => {
  const history = useHistory();

  const { createdAt, artwork, user, title, trackByRelease, slug } = release;

  const { username, _id: userId, slug: slugUser, avatar } = user;

  return (
    <Box border="1px solid #dbdbdb" borderRadius="5px">
      <Box className="img-square-outer">
        <Box className="img-square-inner">
          <Link className="release-hover-text" to={`/multipacks/${slug}`}>
            <Box
              className="release-hover-title"
              fontSize="25px"
              fontWeight="700"
              lineHeight="30px"
            >
              {title}
            </Box>
            <Box
              mt="5px"
              fontSize="17px"
              fontWeight="600"
              color="white"
              cursor="pointer"
              lineHeight="22px"
            >
              {username}
            </Box>
          </Link>

          <Image className="artwork" alt="" src={artwork} />
        </Box>
      </Box>
      <Flex className="release-date">
        <Text>Release Date:</Text>
        <Text>{formatDate(createdAt)}</Text>
      </Flex>
      <Text
        cursor="pointer"
        onClick={() => {
          history.push(`/multipacks/${slug}`);
        }}
        className="title"
      >
        {title}
      </Text>

      <Flex
        className="author-section"
        alignItems="center"
        alignContent="center"
      >
        <Box className="avatar">
          {avatar ? (
            <Image width="100%" height="100%" src={avatar} />
          ) : (
            <FaUser fontSize={20} />
          )}
        </Box>
        <Text
          className="author"
          cursor="pointer"
          onClick={() => {
            if (user?.role !== Role.ADMIN) {
              history.push(`/contributors/${slugUser}`);
            }
          }}
        >
          {username}
        </Text>
      </Flex>
      <Flex className="action-section">
        <Box className="track">
          <Text fontWeight={800}> {trackByRelease ?? 0} TRACKS </Text>
        </Box>
        <Box className="save">
          <Text>SAVE {formatMoney(release?.savePrice)} </Text>
        </Box>
        <Box className="cart">
          {userId !== release?.userId ? (
            <Flex justifyContent="center" alignItems="center" h="30px" flex={1}>
              <CartButton
                isChartsPage={isChartsPage}
                updateWishlistStatusForTopRelease={
                  updateWishlistStatusForTopRelease
                }
                isRelease
                release={release}
              />
            </Flex>
          ) : (
            <Flex justifyContent="center" alignItems="center" h="30px" flex={1}>
              <Text fontSize="12px" fontWeight={700} textAlign="end">
                {formatMoney(release.price)}
              </Text>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default ReleaseItem;
