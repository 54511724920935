import { useGenresSlice } from 'app/pages/Genres/slice';
import { selectSliceGenres } from 'app/pages/Genres/slice/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useGenres = () => {
  const { genres, isLoading } = useSelector(selectSliceGenres);

  const dispatch = useDispatch();
  const { actions } = useGenresSlice();
  const onGetGenres = useCallback(
    (payload?: any) => {
      dispatch(actions.getGenresRequest(payload));
    },
    [actions, dispatch],
  );

  return {
    onGetGenres,
    genres,
    isLoading,
  };
};
