import { Box, Image } from '@chakra-ui/react';
import { memo, useEffect } from 'react';
import { useSections } from 'app/hooks/sections/useSections';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Section } from 'app/models';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export const BannerListLabel = memo(() => {
  const { getAllLabels, allLabels } = useSections();
  const history = useHistory();
  const { pathname } = useLocation();

  const isContributorsPage = pathname.includes('/contributors');
  const isMultipacksPage = pathname.includes('/multipacks');

  useEffect(() => {
    getAllLabels();
  }, [getAllLabels]);

  const buildUrl = (
    label: Section,
    isMultipacksPage: boolean,
    isContributorsPage: boolean,
  ) => {
    if (isMultipacksPage) {
      return {
        pathname: '/multipacks',
        state: {
          type: 'showSections',
          value: label?._id,
          label: label?.name,
        },
      };
    }
    return `labels/${label?.slug}${isContributorsPage ? '?tab=3' : '?tab=1'}`;
  };

  const CustomPrevArrow = props => {
    const { className, onClick, style } = props;
    return (
      <Box
        as="button"
        className={className}
        onClick={onClick}
        style={{ ...style, display: 'block', left: '-25px' }}
      >
        <ChevronLeftIcon w={8} h={8} color="#000" />
      </Box>
    );
  };

  const CustomNextArrow = props => {
    const { className, onClick, style } = props;
    return (
      <Box
        as="button"
        className={className}
        onClick={onClick}
        style={{
          ...style,
          display: 'block',
          right: '-25px',
        }}
      >
        <ChevronRightIcon w={8} h={8} color="#000" />
      </Box>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <Box
      mb={{ base: '28px', md: '20px', xl: '20px' }}
      className="banner-container"
    >
      <Slider {...settings} className="list-labels">
        {allLabels?.map((label: Section) => (
          <Box key={label?._id} pr="7px" pl="7px">
            <Image
              className="label-item"
              onClick={() =>
                history.push(
                  buildUrl(label, isMultipacksPage, isContributorsPage),
                )
              }
              src={label?.squareImageUrl}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
});
