import { Button, Text } from '@chakra-ui/react';
import { useModeTheme } from 'app/hooks/ColorDarkMode/useModeTheme';

function IconReturn(props) {
  const { isDarkMode } = useModeTheme();
  const color = isDarkMode ? '#FFFFFF' : '#000';

  return (
    <Button
      h="30px"
      p="5px"
      rightIcon={
        <svg
          width="16"
          height="16"
          style={{ marginBottom: '4px' }}
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5157 3.05508V0.668648C10.5157 0.0687061 9.80607 -0.224599 9.39867 0.202026L5.71892 3.92167C5.45608 4.18831 5.45608 4.6016 5.71892 4.86824L9.38553 8.58788C9.80607 9.00117 10.5157 8.70787 10.5157 8.10793V5.72149C14.8657 5.72149 18.4009 9.30781 18.4009 13.7207C18.4009 17.347 15.9959 20.4134 12.7367 21.3866C12.1848 21.5466 11.8299 22.0799 11.8299 22.6532C11.8299 23.5198 12.6447 24.1997 13.4727 23.9464C15.6529 23.3002 17.5678 21.9543 18.9303 20.1107C20.2927 18.267 21.0292 16.0249 21.0293 13.7207C21.0293 7.82796 16.3245 3.05508 10.5157 3.05508ZM2.63055 13.7207C2.63055 11.9342 3.2088 10.2811 4.19445 8.93451C4.58871 8.40123 4.53614 7.66797 4.07617 7.18802C3.52421 6.62807 2.57799 6.6814 2.10488 7.32134C1.08502 8.69875 0.409633 10.3065 0.136616 12.0068C-0.1364 13.7071 0.00109622 15.4492 0.537319 17.0837C1.07354 18.7183 1.9925 20.1966 3.2154 21.3918C4.43831 22.5871 5.9287 23.4636 7.55879 23.9464C8.38674 24.1997 9.20154 23.5198 9.20154 22.6532C9.20154 22.0799 8.84671 21.5466 8.29474 21.3866C5.03553 20.4134 2.63055 17.347 2.63055 13.7207Z"
            fill={color}
          />
        </svg>
      }
      colorScheme="black"
      variant="outline"
      fontWeight={600}
    >
      <Text color={color} fontSize={{ base: '12px', md: '14px' }}>
        Reset All
      </Text>
    </Button>
  );
}

export default IconReturn;
