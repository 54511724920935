import { Icon } from '@chakra-ui/react';

export default function IconMedia() {
  return (
    <Icon
      w="20px"
      h="20px"
      viewBox="0 0 12 16"
      fill="black"
      _dark={{ fill: 'white' }}
    >
      <path d="M7.293 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H10C10.5304 16 11.0391 15.7893 11.4142 15.4142C11.7893 15.0391 12 14.5304 12 14V4.707C11.9999 4.4418 11.8945 4.18749 11.707 4L8 0.293C7.81251 0.105451 7.5582 5.66374e-05 7.293 0V0ZM7.5 3.5V1.5L10.5 4.5H8.5C8.23478 4.5 7.98043 4.39464 7.79289 4.20711C7.60536 4.01957 7.5 3.76522 7.5 3.5ZM9 6.64V8.39L7 8.89V12.5C7 12.995 6.699 13.383 6.338 13.623C5.974 13.866 5.499 14 5 14C4.5 14 4.026 13.866 3.662 13.623C3.302 13.383 3 12.995 3 12.5C3 12.005 3.301 11.617 3.662 11.377C4.026 11.134 4.501 11 5 11C5.356 11 5.7 11.068 6 11.196V6.89C6.00001 6.66707 6.0745 6.45054 6.21166 6.27479C6.34881 6.09905 6.54075 5.97417 6.757 5.92L7.757 5.67C7.90445 5.63306 8.05838 5.63023 8.20709 5.6617C8.3558 5.69318 8.49538 5.75815 8.61521 5.85167C8.73505 5.94518 8.83199 6.06479 8.89866 6.20139C8.96534 6.33799 9 6.48799 9 6.64Z"></path>
    </Icon>
  );
}
